<template>
  <div>
    <el-drawer class="appealRecordDetail-drawer" title="质检记录详情" :visible.sync="drawer" :append-to-body="true" direction="rtl" :before-close="handleClose">
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="drawer-container">
        <div class="drawer-left">
          <div>
              <div class="detail-title">
                  <span>{{ tableRow.qualityCode || "--" }}</span>
                  <div class="common-tableStatus-blue" v-if="tableRow.qualityStatus == 'noNeed'">客服无需申诉</div>
                  <div class="common-tableStatus-grey" v-if="tableRow.qualityStatus == 'fail'">客服申诉失败</div>
                  <div class="common-tableStatus-blue" v-if="tableRow.qualityStatus == 'success'">客服申诉成功</div>
                  <div class="common-tableStatus-orange" v-if="tableRow.qualityStatus == 'appealing'">待质检评审</div>
                  <div class="common-tableStatus-red" v-if="tableRow.qualityStatus == 'launch'">待客服申诉</div>
              </div>
              <div class="detail-content">
                  <div class="detail-item">店铺名称：<span>{{tableRow.shopName || '--'}}</span></div>
                  <div class="detail-item">所属客户：<span>{{tableRow.customerName || '--'}}</span></div>
                  <div class="detail-item">所属公司：<span>{{tableRow.companyName || '--'}}</span></div>
                  <div class="detail-item">所属平台：<span>{{tableRow.platformName || '--'}}</span></div>
                  <div class="detail-item">所属类目：<span>{{tableRow.categoryName || '--'}}</span></div>
                  <div class="detail-item">顾客账号：<span>{{tableRow.customerAccount || '--'}}</span></div>
                  <div class="detail-item">质检评分：<span>{{tableRow.score || tableRow.score == 0 ? tableRow.score : '--'}}</span></div>
                  <div class="detail-item">扣款金额：<span>{{tableRow.amount || tableRow.amount == 0 ? tableRow.amount : '--'}}</span></div>
                  <div class="detail-item">受检客服：<span>{{tableRow.serviceInspected || '--'}}</span></div>
                  <div class="detail-item">客服子账号：<span>{{tableRow.subaccountName || '--'}}</span></div>
                  <div class="detail-item">质检人员：<span>{{tableRow.qualityName || '--'}}</span></div>
                  <div class="detail-item">业务类型：<span>{{tableRow.businessType || '--'}}</span></div>
                  <div class="detail-item">质检时间：<span>{{tableRow.qualityTime || '--'}}</span></div>
              </div>
              <div class="detail-remark"><span>备注：</span> <span>{{tableRow.remark || '--'}}</span></div>
          </div>
          <el-tabs class="leftTabs" v-model="activeNameLeft">
            <el-tab-pane label="申诉列表" name="appeal">
                <el-table class="common-table" :data="appealTableData">
                    <el-table-column width="260px" label="申诉详情">
                      <template slot-scope="scope"> 
                        <div class="common-table-btn" v-if="scope.row.deleteFlag == '1'" style="text-decoration:line-through;color: #ccc">{{scope.row.appealContent}}</div>
                        <div class="common-table-btn" v-else-if="scope.row.status != 'pending'" style="color: #ccc">{{scope.row.appealContent}}</div>
                        <div class="common-table-btn" v-else @click="() => {appealEditShow(scope.row)}">{{scope.row.appealContent}}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="createName" label="发起人" :formatter="tableColumn"></el-table-column>
                    <el-table-column prop="createTime" label="发起时间" :formatter="tableColumn"></el-table-column>
                    <!-- <el-table-column prop="adoptTime" label="质检处理时间" :formatter="tableColumn"></el-table-column>
                    <el-table-column prop="adoptName" label="质检人" :formatter="tableColumn"></el-table-column>
                    <el-table-column prop="score" label="评分" :formatter="tableColumn"></el-table-column>
                    <el-table-column prop="amount" label="扣款金额" :formatter="tableColumn"></el-table-column> -->
                    <el-table-column prop="status" label="申诉状态" :formatter="tableColumn">
                      <template slot-scope="scope"> 
                        <div class="common-tableStatus-grey" v-if="scope.row.deleteFlag == '1'">已作废</div>
                        <div class="common-tableStatus-blue" v-else-if="scope.row.status == 'adopt'">已通过</div>
                        <div class="common-tableStatus-red" v-else-if="scope.row.status == 'fail'">未通过</div>
                        <div class="common-tableStatus-green" v-else-if="scope.row.status == 'pending'">待审核</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="140px">
                      <template slot-scope="scope"> 
                        <div>
                          <el-button v-if="scope.row.status == 'pending' && scope.row.deleteFlag != '1'" @click="() => {voidShow(scope.row.id)}" type="text" size="small">作废</el-button>
                          <el-button v-else style="color: #ccc" type="text" size="small">作废</el-button>
                        </div>
                      </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    @size-change="(e) => pageFun(e, 'pageSize')"
                    @current-change="(e) => pageFun(e, 'pageNum')"
                    style="text-align: center"
                    :page-sizes="pagination.pageSizes"
                    :page-size="appealParams.pageSize"
                    :current-page="appealParams.pageNum"
                    layout="total, prev, pager, next,sizes, jumper"
                    :total="pagination.total"></el-pagination>
                    <br/>
            </el-tab-pane>
            <el-tab-pane label="聊天记录" name="ChatRecord">
              <div>
                <div class="chatItem" v-for="(item, index) in chatData" :key="index">
                  <div class="chatItem-title">
                    <div>{{item.fromId}}</div>
                    <div>{{DateTimeTransform(item.msgTime)}}</div>
                  </div>
                  <div class="chatItem-content" v-if="item.msgType === 0">{{item.msgContent}}</div>
                  <a :href="item.msgContent" target="_blank" v-else-if="item.msgType === 1">点击跳转</a>
                  <span class="chatItem-content" v-else-if="item.msgType === 2" >【{{ item.msgContent }}】--苹果手机表情+文字（不兼容）</span>
                  <span class="chatItem-content" v-else-if="item.msgType === 3" >【表情图暂不支持查看】</span>
                  <a :href="item.msgContent" target="_blank" v-else-if="item.msgType === 5" >淘宝链接--点击查看
                  </a>
                  <a target="_blank" :href="item.msgContent" v-else-if="item.msgType === 6" >【视频资源——点击查看】</a>
                  <img class="chartItem-img" :src="item.msgContent" v-if="item.msgType === 7" @click="showImg(item.msgContent)" />
                  <span class="chatItem-content" v-else-if="item.msgType === 9">【{{ item.msgContent }}】</span>
                  <span class="chatItem-content" v-else-if="item.msgType === 10">[{{ item.msgContent }}] </span>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="问题描述" name="problem">
              <div class="detail-content" style="margin-top: 16px;width:100%">
                <div>
                  <div class="detail-item">问题大类：<span>{{ tableRow.classificationQuality && tableRow.classificationQuality.bigList[0] || '--'}}</span></div>
                  <div class="detail-item" style="margin-top: 16px;">问题点：<span>{{ tableRow.classificationQuality && tableRow.classificationQuality.pointList[0] || '--'}}</span></div>
                </div>
                <div class="detail-remark"><span>问题描述：</span> <span>{{ tableRow.classificationQuality && tableRow.classificationQuality.descList[0] || '--'}}</span></div>
                <div>
                  <div class="detail-item" style="margin-top: 16px;">品牌问题大类：<span>{{ tableRow.classificationBrand && tableRow.classificationBrand.bigList[0] || '--'}}</span></div>
                  <div class="detail-item" style="margin-top: 16px;">品牌问题点：<span>{{ tableRow.classificationBrand && tableRow.classificationBrand.pointList[0] || '--'}}</span></div>
                </div>
                <div class="detail-remark"><span>品牌问题描述：</span> <span>{{ tableRow.classificationBrand && tableRow.classificationBrand.descList[0] || '--'}}</span></div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="drawer-right">
          <el-tabs style="height: 100%;display: flex;flex-direction: column;" v-model="activeName">
            <el-tab-pane label="发起申诉" name="appeal">
              <div>
                <div style="margin-bottom: 14px;">申诉原因说明：</div>
                <el-input :disabled="tableRow.qualityStatus == 'launch' || tableRow.qualityStatus == 'fail' ? false : true" :autosize="{ minRows: 6, maxRows: 6 }" type="textarea" v-model="recordContent"></el-input>
                <div>
                  <div class="fileItem" v-for="(item, index) in filesList" :key="index">
                    <div><common-picture :fileUrl="item.fileUrl" :fileType="Config.fileType.imgType.includes(item.fileUrl.split('.')[item.fileUrl.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.fileName"></common-picture></div>
                    <div class="common-blue-btn" @click="() => deleteFile(item.fileId)">删除</div>
                  </div>
                </div>
                <div class="appeal-commit">
                  <div>
                    <div class="addFileBtn">
                      <img style="margin-right: 8px" src="../../../assets/images/add_file_icon.svg" />添加附件
                      <input :disabled="tableRow.qualityStatus == 'launch' || tableRow.qualityStatus == 'fail' ? false : true" class="addFileInput" :multiple="false" @input="chooseFile" type="file" />
                    </div>
                    <div style="font-size: 12px; color: #ccc"> 上传文件及图片，大小不超过50M </div>
                  </div>
                  <el-button :disabled="tableRow.qualityStatus == 'launch' || tableRow.qualityStatus == 'fail' ? false : true" :loading="btnLoading" @click="() => {qualityAppeal()}" class="common-screen-btn" type="primary" >发起申诉</el-button>
                </div>
              </div>
              <common-work-log :followRecordList="followRecordList"></common-work-log>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
    <el-dialog title="申诉详情" :append-to-body="true" :visible.sync="appealVisible" width="520px" :close-on-click-modal="false">
      <div>
        <el-form :model="appealEditForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="原因说明：">
            <el-input class="common-screen-input_100" type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容" v-model="appealEditForm.appealContent"></el-input>
          </el-form-item>
          <el-form-item>
            <div>
              <div>
                <div class="common-file-grey">
                  上传附件
                  <input type="file" :multiple="false" @input="chooseFileEdit">
                </div>
                <span style="color: #ccc;font-size: 12px;margin-left:12px">上传文件及图片，大小不超过50MB</span>
              </div>
              <div class="fileItem" v-for="(item, index) in editFilesList" :key="index">
                <div><common-picture :fileUrl="item.fileUrl" :fileType="Config.fileType.imgType.includes(item.fileUrl.split('.')[item.fileUrl.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.fileName"></common-picture></div>
                <div class="common-blue-btn" style="font-size: 14px" @click="() => editFile(index)">删除</div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="appealVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" :loading="btnLoading" type="primary" @click="() => {qualityAppeal('edit')}">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="作废说明" :append-to-body="true" :visible.sync="voidVisible" width="500px" :close-on-click-modal="false">
      <div>
        <el-form :model="voidForm" :rules="voidRules" ref="voidForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="作废原因：" prop="deleteReason">
            <el-input class="common-screen-input_100" type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容" v-model="voidForm.deleteReason"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="voidVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" :loading="btnLoading" type="primary" @click="() => {discardAppeal('voidForm')}">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex"
import { Config, tableColumn, DateTimeTransform } from "../../../utils/index.js"
import CommonPicture from "../../../components/common/commonPicture.vue"
import { upload } from "../../../service/upload.js"
import { qualityAppeal, qualityHisDetail, appealList, discardAppeal } from "../../../service/service.js"
import CommonWorkLog from "../../../components/common/commonWorkLog.vue"
import { workLog }  from "../../../service/common.js"
export default {
  props: ["drawer", "handleClose"],
  components: { CommonPicture, CommonWorkLog },
  data() {
    return {
      DateTimeTransform,
      loading: false,
      voidVisible: false,
      appealVisible: false,
      Config,
      tableColumn,
      activeName: "appeal", // tabs
      activeNameLeft: "appeal", // 左侧tabs
      recordContent: '', // 申诉原因
      appealTableData: [], // 申诉列表
      appealParams: { // 申诉列表
        pageNum: 1,
        pageSize: 10
      }, 
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      voidForm: {
        deleteReason: ''
      },  // 作废表单
      voidRules: { // 校验规则-作废
        deleteReason: [{ required: true, message: "请填写作废原因", trigger: "change" }]
      },
      appealEditForm: {},
      filesList: [],  // 申诉文件上传
      editFilesList: [], // 申诉文件编辑
      tableRow: {}, // 列表一行信息
      chatData: [], // 聊天数据
      followRecordList: [], // 工作日志
    };
  },
  created() {},
  computed: {
    ...mapState(["btnLoading"])
  },
  methods: {
    async workLog(id) { // 工作日志
      let resData = (await workLog({id: id, type: 3})).data
      if (resData && resData.length) {
        resData.map((item) => {
          if (item.value) {
            item.value = JSON.parse(item.value)
          } else {
            item.value = []
          }
        })
      }
      this.followRecordList = [...resData]
    },
    async appealList() { // 申诉列表
    let params = {...this.appealParams}
    params.qualityCode = this.tableRow.qualityCode
      let { data } = await appealList(params)
      this.appealTableData = data.list || []
      this.pagination.total = data.total;
    },
    async qualityHisDetail() {// 聊天记录
      let params = { qualityCode: this.tableRow.qualityCode }
      let { data } = await qualityHisDetail(params)
      this.chatData = data
    },
    tableParams (row) { // 获取列表的值
      this.tableRow = row
      this.qualityHisDetail()
      this.appealList()
      this.workLog(row.id)
    },
    async qualityAppeal(type) { // 质检申诉-客服发起
      let filesList = []
      let params = {}
      if (type == 'edit') {
        filesList = [...this.editFilesList]
      } else {
        filesList = [...this.filesList]
      }
      let urlArr = []
      let nameArr = []
      filesList.map((item) => {
        urlArr.push(item.fileUrl)
        nameArr.push(item.fileName)
      })
      
      if (type == 'edit') {
        params = {...this.appealEditForm, fileUrl: urlArr.join(','), fileNames: nameArr.join(',')}
      } else {
        params = {
          qualityCode: this.tableRow.qualityCode,
          appealContent: this.recordContent,
          fileUrl: urlArr.join(','),
          fileNames: nameArr.join(',')
        }
      }
      this.loading = true
      let res = await qualityAppeal(params)
      this.loading = false
      if (res.code == 200) {
        if (type == 'edit') {
          this.$message.success("编辑成功")
          this.appealVisible = false
        } else {
          this.$message.success("发起申诉")
          this.filesList = []
          this.recordContent = ''
        }
        this.appealList()
        this.workLog(this.tableRow.id)
      }
    },
    appealEditShow (row) { // 申诉编辑弹窗
      let newArr = []
      if (row.fileNames) {
        let fileNamesArr = row.fileNames.split(',')
        let fileUrlsArr = row.fileUrl.split(',')
        fileNamesArr.map((item, index) => {
          let fileType = ''
          let format = item.split('.')[item.split('.').length - 1]
          if ( format == "bmp" || format == "jpg" || format == "png" || format == "gif" || format == "jpeg" ) {
            fileType = "img";
          }
          newArr.push({
            fileType: fileType,
            fileName: item,
            fileUrl: fileUrlsArr[index],
          })
        })
        this.editFilesList = newArr
      }
      this.appealEditForm = {
        id: row.id,
        qualityCode: this.tableRow.qualityCode,
        appealContent: row.appealContent,
      }
      this.appealVisible = true
    },
    discardAppeal (formName) { // 作废提交
      let voidForm = {...this.voidForm}
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.$vuex.commit("btnLoading", true);
          let res = await discardAppeal(voidForm)
          this.$vuex.commit("btnLoading", false);
          this.voidVisible = false
          if (res.code == 200) {
            this.$message.success("作废成功")
            this.appealList()
            this.workLog(this.tableRow.id)
          }
        } else {
          return false;
        }
      });
    },
    voidShow (id) { // 作废按钮
      this.voidForm.id = id
      this.voidForm.deleteReason = ''
      this.voidVisible = true
      if (this.$refs["voidForm"]) {
        this.$refs["voidForm"].resetFields();
      }
    },
    editFile (i) { // 删除申诉文件
      let editFilesList = [...this.editFilesList]
      this.editFilesList = editFilesList.filter((item, index)=>{
        return index != i
      })
    },
    deleteFile (id) { // 删除申诉文件
      let filesList = [...this.filesList]
      this.filesList = filesList.filter((item)=>{
        return item.fileId != id
      })
    },
    async chooseFileEdit(e) { // 编辑申诉选择文件
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        e.target.value = "";
        return this.$message.error('文件大小不能超过50M')
      }
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
      }
      this.loading = true
      let resData = (await upload({ file: file })).data;
      this.loading = false
      let fileItem = {
        fileType: "",
        fileName: file.name,
        fileId: resData.id,
        fileUrl: resData.filename,
      };
      if ( format == "bmp" || format == "jpg" || format == "png" || format == "gif" || format == "jpeg" ) {
        fileItem.fileType = "img";
      }
      this.editFilesList.push(fileItem);
    },
    async chooseFile(e) { // 发起申诉选择文件
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        e.target.value = "";
        return this.$message.error('文件大小不能超过50M')
      }
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
      }
      this.loading = true
      let resData = (await upload({ file: file })).data;
      this.loading = false
      let fileItem = {
        fileType: "",
        fileName: file.name,
        fileId: resData.id,
        fileUrl: resData.filename,
      };
      if ( format == "bmp" || format == "jpg" || format == "png" || format == "gif" || format == "jpeg" ) {
        fileItem.fileType = "img";
      }
      this.filesList.push(fileItem);
    },
    pageFun(e, type) { // 分页
      this.appealParams[type] = e;
      this.appealList()
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-tabs__content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
/deep/.el-tab-pane {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.fileItem {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.appealRecordDetail-drawer {
  text-align: left;
  color: #101010;
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .drawer-left {
      padding: 0 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
      .detail-title {
        line-height: 78px;
        span:nth-of-type(1) {
          font-size: 18px;
          margin-right: 16px;
        }
      }
      .detail-content {
        width: 900px;
      }
      .detail-item {
        width: 32%;
        display: inline-block;
        margin-bottom: 16px;
        color: #999;
        span {
          color: #666;
        }
      }
      .detail-remark {
        display: flex;
        width: 80%;
        span {
          display: inline-block;
          &:nth-of-type(1) {
            color: #999;
            white-space: nowrap;
          }
          &:nth-of-type(2) {
            color: #666;
          }
        }
      }
      .leftTabs {
        margin-top: 36px;
        .chatItem {
          color: #101010;
          font-size: 14px;
          margin-top: 8px;
          .chatItem-title {
            display: flex;
            justify-content: space-between;
            line-height: 38px;
          }
          .chatItem-content {
            line-height: 38px;
            background: #F7F8FB;
            padding-left: 10px;
          }
          .chartItem-img {
            width: 80px;
          }
        }
      }
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      .appeal-commit {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        align-items: center;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
            cursor: pointer;
            font-size: 0;
          }
        }
      }
      
    }
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
      width: 1656px !important;
  }
  /deep/ .el-drawer__body {
      padding: 0;
  }
  /deep/ .el-tabs__content {
      padding: 0 8px 0 16px;
  }
}

</style>
